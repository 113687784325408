import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as aiChatDuck from "../../../app/store/ducks/ai-chat.duck";
import { toAbsoluteUrl } from "../../../_metronic";
import { Formik } from "formik";
import { CircularProgress, InputBase, useMediaQuery } from "@material-ui/core";
import LoadingDots from "../../../app/components/LoadingDots";
import { createChat, getChatList, getChatMessages, sendChatMessage } from "../../../app/crud/ai-chat.crud";
import Swal from "sweetalert2";
import BetterCircularProgress from "../../../app/components/BetterCircularProgress";
import ReactMarkdown from "react-markdown";

const AIChat = () => {
	const [showChatListToggle, setShowChatListToggle] = useState(false);
	const [showAiChat, setShowAiChat] = useState(false);
	const [closeAiChatAnimation, setCloseAiChatAnimation] = useState(false);
	const [loadingChat, seLoadingChat] = useState(false);
	const [loadingChatList, seLoadingChatList] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [handsHover, setHandsHover] = useState({
		messageId: null,
		up: false,
		down: false,
	});
	const [chatList, setChatList] = useState([]);
	const [chatMessages, setChatMessages] = useState([]);
	const [inputHeight, setInputHeight] = useState(50);

	const { toggleChat, toggleExpandedView, toggleShowChatList, selectedChat } = useSelector((state) => state.aiChat);

	const messagesContainerRef = useRef();
	const dispatch = useDispatch();
	const isMobile = useMediaQuery("(max-width:1024px)");

	const chatListRequest = useCallback(() => {
		seLoadingChatList(true);
		getChatList()
			.then((res) => {
				if (res.data) {
					setChatList(res.data);
				}
			})
			.catch((err) => Swal.fire("Ops", "Ocorreu um erro ao listar conversas. Por favor, tente novamente.", "error"))
			.finally(() => seLoadingChatList(false));
	}, []);

	const getChatMessagesRequest = useCallback((id) => {
		seLoadingChat(true);
		getChatMessages(id)
			.then((res) => {
				if (res.data) {
					setChatMessages(res.data);
					dispatch(aiChatDuck.actions.selectChat({ mensagens: res.data }));
					dispatch(aiChatDuck.actions.toggleShowChatList(false));

					setTimeout(() => {
						handleBottomMessageScroll();
					}, 200);
				}
			})
			.catch((err) => {
				Swal.fire("Ops", "Ocorreu um erro ao listar mensagens. Por favor, tente novamente.", "error");
				dispatch(aiChatDuck.actions.selectChat(null));
				setChatMessages([]);
			})
			.finally(() => seLoadingChat(false));
	}, []);

	/**
	 * 1. Esconde o botão de whatsapp para não atrapalhar o chat
	 * 2. Força visualização expandida para mobile (< 1024px)
	 */
	useEffect(() => {
		const whatsappLink = document.querySelector(".whatsapp-float");

		if (showAiChat) {
			whatsappLink.setAttribute("style", "display: none;");
			chatListRequest();
			handleBottomMessageScroll();
		} else {
			whatsappLink.setAttribute("style", "display: inline-block;");
		}

		if (isMobile && toggleExpandedView) {
			dispatch(aiChatDuck.actions.toggleExpandedView());
		}
	}, [showAiChat]);

	/**
	 * Controla animação de abrir e fechar chat
	 */
	useEffect(() => {
		if (toggleChat) {
			setShowAiChat(true);
			setCloseAiChatAnimation(false);
		} else {
			setCloseAiChatAnimation(true);

			setTimeout(() => {
				setShowAiChat(false);
				setCloseAiChatAnimation(false);
			}, 500);
		}
	}, [toggleChat]);

	/**
	 * Controla animação de mostrar e esconder botão de mostrar lista de chat
	 */
	useEffect(() => {
		if (Boolean(chatList.length)) {
			setTimeout(() => {
				setShowChatListToggle(true);
			}, 500);
		} else {
			setTimeout(() => {
				setShowChatListToggle(false);
			}, 500);
		}
	}, [chatList]);

	/**
	 * Força scroll para a mensagem do chat enviada ou recebida
	 */
	useEffect(() => {
		handleBottomMessageScroll();
	}, [chatMessages]);

	/**
	 * Força modelo não expandido para mobile
	 */
	useEffect(() => {
		if (isMobile && toggleExpandedView) {
			dispatch(aiChatDuck.actions.toggleExpandedView());
		}
	}, [isMobile]);

	const onSubmit = (values, { resetForm, setFieldValue }) => {
		const mensagem = values.mensagem;

		if (mensagem) {
			const msgs = [...chatMessages, { chm_tipo_autor: "user", chm_mensagem: mensagem }];
			setChatMessages(msgs);
			setSubmitting(true);
			handleBottomMessageScroll();
			resetForm();

			if (selectedChat?.id) {
				/**
				 * Se chat existente
				 */
				sendChatMessage({
					question: mensagem,
					id_assistente: selectedChat.cha_id_assistente,
					id_chat: selectedChat.id,
				})
					.then((res) => {
						if (res.data) {
							setChatMessages([...msgs, res.data]);
							dispatch(aiChatDuck.actions.selectChat({ cha_nome: res.data.chatNewName ?? selectedChat.cha_nome, mensagens: [...msgs, res.data] }));
							setChatList((list) => list.map((chat) => (chat.id === res.data.chm_id_chat && res.data.chatNewName ? { ...chat, cha_nome: res.data.chatNewName } : chat)));
						}
					})
					.catch((err) => {
						Swal.fire("Ops", "Ocorreu um erro ao enviar mensagem. Por favor, tente novamente.", "error");
						setChatMessages(selectedChat?.mensagens ?? []);
						setFieldValue("mensagem", mensagem);
					})
					.finally(() => setSubmitting(false));
			} else {
				/**
				 * Se chat novo
				 */

				const id_assistente = 2;

				createChat({
					id_assistente,
					question: mensagem,
				})
					.then((res) => {
						if (res.data) {
							const novoChat = { id: res.data.chm_id_chat, cha_nome: res.data.chatNewName, cha_id_assistente: id_assistente, mensagens: [...msgs, res.data] };
							setChatMessages(novoChat.mensagens);
							dispatch(aiChatDuck.actions.selectChat(novoChat));
							setChatList((prev) => [novoChat, ...prev]);
						}
					})
					.catch((err) => {
						Swal.fire("Ops", "Ocorreu um erro ao enviar mensagem. Por favor, tente novamente.", "error");
						setChatMessages(selectedChat?.mensagens ?? []);
						setFieldValue("mensagem", mensagem);
					})
					.finally(() => setSubmitting(false));
			}
		}
	};

	/**
	 * Força scroll com animação leve para o final da conversa (últimas mensagens)
	 */
	const handleBottomMessageScroll = () => {
		if (messagesContainerRef.current) {
			messagesContainerRef.current.scrollTo({ top: messagesContainerRef.current.scrollHeight, behavior: "smooth" });
		}
	};

	const handleFeedback = (value, message) => {
		alert(`${value ? "positivo" : "negativo"} - Mensagem ${message.id}`);
	};

	const showChatList = (e) => {
		e.preventDefault();
		dispatch(aiChatDuck.actions.toggleShowChatList());
		setInputHeight(50);
	};

	const expandView = (e) => {
		e.preventDefault();
		dispatch(aiChatDuck.actions.toggleExpandedView());
	};

	const closeChat = (e) => {
		e.preventDefault();
		if (selectedChat) {
			dispatch(aiChatDuck.actions.selectChat(null));
			setChatMessages([]);
		} else {
			dispatch(aiChatDuck.actions.toggleChat());
		}
	};

	const handleChat = ({ id, cha_nome, cha_id_assistente }) => {
		dispatch(aiChatDuck.actions.selectChat({ id, cha_nome, cha_id_assistente }));
		getChatMessagesRequest(id);
		setInputHeight(50);
	};

	/**
	 * Normaliza resposta para renderização fiél ao retorno
	 */
	const formatText = (text) => {
		return <ReactMarkdown className="message">{text}</ReactMarkdown>;
	};

	return showAiChat ? (
		<div className="ai-chat-container">
			<div className={`ai-chat-container-content ${closeAiChatAnimation ? "close" : ""} ${toggleExpandedView ? `ai-chat-container-content-expanded` : ``}`}>
				<div className="ai-chat-options-container">
					<div className={`ai-chat-options-btns-left ${!Boolean(chatList.length) ? "hideToggleChatList" : "showToggleChatList"}`}>
						{toggleExpandedView ? null : (
							<button className={`btn-chat-options toggleChatList ${showChatListToggle ? "" : "hide"} ${Boolean(chatList.length) ? "onShow" : ""}`} onClick={(e) => showChatList(e)}>
								<img src={toAbsoluteUrl("/images/ai/bars-3.svg")} style={{ height: 19, width: 19 }} />
							</button>
						)}

						{!isMobile ? (
							<button className="btn-chat-options" onClick={(e) => expandView(e)}>
								<img src={toAbsoluteUrl(`/images/ai/arrows-pointing-${toggleExpandedView ? "in" : "out"}.svg`)} style={{ height: 19, width: 19 }} />
							</button>
						) : null}
					</div>

					{selectedChat && !loadingChat ? (
						<span title={selectedChat.cha_nome} className="ai-chat-name">
							{selectedChat.cha_nome}
						</span>
					) : null}

					<div className="ai-chat-options-btns-right">
						<button className="btn-chat-options" onClick={(e) => closeChat(e)}>
							<img src={toAbsoluteUrl("/images/ai/left.svg")} style={{ height: 19, width: 19 }} />
						</button>
					</div>
				</div>

				<div className={`ai-chat-body ${toggleExpandedView ? "expanded" : ""}`}>
					<div className={`ai-chat ${toggleShowChatList ? "" : "active"} ${Boolean(chatMessages.length) ? "" : "newChat"} ${toggleExpandedView ? "expanded" : ""}`} style={{ height: `calc(100vh - ${221 + inputHeight}px)` }}>
						<BetterCircularProgress loading={loadingChat}>
							<div ref={messagesContainerRef} className={`ai-chat-messages-container ${Boolean(chatMessages.length) ? "" : "newChat"}`}>
								{!Boolean(chatMessages.length) ? (
									<span className="ai-chat-welcome-text">Como posso ajudar você hoje?</span>
								) : (
									chatMessages.map((message) =>
										message.chm_tipo_autor === "assistant" ? (
											<div className="ai-chat-message-from-ai">
												<img className="ai-chat-message-from-ai-avatar" src={toAbsoluteUrl(`/images/ai/assistant-avatar.png`)} />

												<div className="ai-chat-message-from-ai-message-container">
													{formatText(message.chm_mensagem)}

													{/* <div className="feedback">
														<button type="button" onClick={(e) => handleFeedback(true, message)} onMouseOver={() => setHandsHover({ up: true, messageId: message.id })} onMouseLeave={() => setHandsHover({ up: false })}>
															<img src={toAbsoluteUrl(`/images/ai/hand-thumb-up${handsHover.up && message.id === handsHover.messageId ? "-filled" : ""}.svg`)} style={{ height: 16, width: 16 }} />
														</button>

														<button type="button" onClick={(e) => handleFeedback(false, message)} onMouseOver={() => setHandsHover({ down: true, messageId: message.id })} onMouseLeave={() => setHandsHover({ down: false })}>
															<img src={toAbsoluteUrl(`/images/ai/hand-thumb-down${handsHover.down && message.id === handsHover.messageId ? "-filled" : ""}.svg`)} style={{ height: 16, width: 16 }} />
														</button>
													</div> */}
												</div>
											</div>
										) : message.chm_tipo_autor === "user" ? (
											<div className="ai-chat-message-from-user">{message.chm_mensagem}</div>
										) : null
									)
								)}

								{submitting ? (
									<div className="ai-chat-message-from-ai">
										<img className="ai-chat-message-from-ai-avatar" src={toAbsoluteUrl(`/images/ai/assistant-avatar.png`)} />

										<div className="ai-chat-message-from-ai-message-container">
											<LoadingDots />
										</div>
									</div>
								) : null}
							</div>

							<div className={`ai-chat-input-container ${Boolean(chatMessages.length) ? "" : "newChat"}`}>
								<Formik initialValues={{ mensagem: "" }} onSubmit={onSubmit}>
									{({ values, handleChange, handleSubmit }) => (
										<form noValidate autoComplete="off" onSubmit={handleSubmit} className="ai-chat-input-text">
											<InputBase
												multiline
												name="mensagem"
												rows={3}
												maxRows={6}
												value={values.mensagem}
												disabled={submitting}
												placeholder="Escreva aqui o que deseja saber"
												onChange={handleChange}
												style={{ flex: 1 }}
												inputProps={{ style: { alignContent: "space-evenly" } }}
												InputProps={{ style: { fontFamily: '"Inter"' } }}
												onKeyDown={(event) => {
													if (event.shiftKey || event.ctrlKey) {
														return;
													}

													if (event.key === "Enter") {
														event.preventDefault();
														handleSubmit();
													}
												}}
												onScrollCapture={(e) => {
													const textArea = e.currentTarget.querySelector("textarea");
													setInputHeight(textArea?.scrollHeight > 95 ? 95 : textArea?.scrollHeight ?? 50);
												}}
											/>

											<button disabled={submitting} className="submit-button" type="submit">
												<img src={toAbsoluteUrl("/images/ai/send-plane-fill.svg")} style={{ height: 24, width: 24 }} />
											</button>
										</form>
									)}
								</Formik>
							</div>
						</BetterCircularProgress>
					</div>

					<div className={`ai-chat chatList ${toggleShowChatList && Boolean(chatList.length) ? "active" : ""} ${toggleExpandedView && Boolean(chatList.length) ? "expanded" : ""}`}>
						<div className="ai-chat-list-container">
							<BetterCircularProgress loading={loadingChatList}>
								{chatList.map((chat) => (
									<div className={`ai-chat-list-item ${selectedChat?.id === chat.id ? "selected" : ""}`} onClick={loadingChat ? undefined : () => handleChat(chat)}>
										{loadingChat && selectedChat?.id === chat.id ? <CircularProgress color="inherit" style={{ color: "#888888", marginRight: 8 }} size={15} /> : null} {chat.cha_nome}
									</div>
								))}
							</BetterCircularProgress>
						</div>
					</div>
				</div>
			</div>
		</div>
	) : null;
};

export default AIChat;
