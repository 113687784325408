import React from "react";

const LoadingDots = () => {
	return (
		<div className="d-flex dot-container">
			<span className="dot animate-bounce delay-0"></span>
			<span className="dot animate-bounce delay-200"></span>
			<span className="dot animate-bounce delay-400"></span>
		</div>
	);
};

export default LoadingDots;
