import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const actionTypes = {
	SelectChat: 'SelectChat',
	ToggleChat: 'ToggleChat',
	ToggleExpandedView: 'ToggleExpandedView',
	ToggleShowChatList: 'ToggleShowChatList',
};

const initialAuthState = {
	selectedChat: null,
	toggleChat: false,
	toggleExpandedView: false,
	toggleShowChatList: false,
};

export const reducer = persistReducer({ storage, key: 'ai-chat', whitelist: ['toggleExpandedView'] }, (state = initialAuthState, action) => {
	switch (action.type) {
		case actionTypes.ToggleChat: {
			return {
				...state,
				toggleChat: !state.toggleChat,
			};
		}

		case actionTypes.ToggleExpandedView: {
			return {
				...state,
				toggleExpandedView: !state.toggleExpandedView,
			};
		}

		case actionTypes.ToggleShowChatList: {
			return {
				...state,
				toggleShowChatList: action.payload ?? !state.toggleShowChatList,
			};
		}

		case actionTypes.UpdateChatList: {
			return {
				...state,
				chatList: action.payload,
			};
		}

		case actionTypes.SelectChat: {
			return {
				...state,
				selectedChat: action.payload
					? {
							...state.selectedChat,
							...action.payload,
					  }
					: null,
			};
		}

		default:
			return state;
	}
});

export const actions = {
	selectChat: (payload) => ({ type: actionTypes.SelectChat, payload }),
	toggleChat: (payload) => ({ type: actionTypes.ToggleChat, payload }),
	toggleExpandedView: (payload) => ({ type: actionTypes.ToggleExpandedView, payload }),
	toggleShowChatList: (payload) => ({ type: actionTypes.ToggleShowChatList, payload }),
};

export function* saga() {}
