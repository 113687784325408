import axios from 'axios';
import { Constants } from '../utils/Constants';

export function getChatList() {
	return axios.get(`${Constants.baseUrlAI}/chat/list-all`);
}

export function getChatMessages(id) {
	return axios.get(`${Constants.baseUrlAI}/chat/${id}`);
}

export function createChat(payload) {
	return axios.post(`${Constants.baseUrlAI}/chat/`, payload);
}

export function sendChatMessage(payload) {
	return axios.post(`${Constants.baseUrlAI}/chat/question`, payload);
}
